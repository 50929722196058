import React from "react"
import { Container, PrimaryBtn } from "components/library"
import { graphql } from "gatsby"
import Success from "images/connect/success.svg"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

const ConnectThankYou = () => {
	const { t } = useTranslation()

	return (
		<main className="overflow-hidden">
			<Container className="flex flex-col gap-20 sm:gap-28 pt-12 sm:pt-20 sm:pb-36">
				<div className="flex flex-col gap-6 items-center text-center">
					<img src={Success} alt="rocket launch" className="w-32" />
					<h1 className="text-3xl sm:text-4xl text-theme-blue max-w-xl">
						{t("connect-page.form.success.heading")}
					</h1>
					<p className="text-xl sm:text-2xl max-w-xl">
						{t("connect-page.form.success.message")}
					</p>
					<Link to="/">
						<PrimaryBtn tabIndex={-1}>{t("common.cta.go-home")}</PrimaryBtn>
					</Link>
				</div>
			</Container>
		</main>
	)
}

export default ConnectThankYou

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
